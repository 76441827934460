import { setCMSBarWidth } from './cms-bar-width'


import './select_checkbox'
import './required_checkbox'

document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')

    for (const item of document.querySelectorAll('.page-navigation li a')) {
        const itemHref = item.getAttribute('href')
        if (typeof itemHref === 'string' && itemHref.includes('#link-hide')) {
            item.removeAttribute('href')
        }
    }


    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }

    const structure = document.querySelector("a[href='?structure']")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }

    const toolbar_link = document.querySelector(".cms-toolbar-item-logo a")
    if (toolbar_link) {
        toolbar_link.after(document.body.dataset.project_version)
    }
})
